<template>
  <v-card
    class="mx-auto limited-width"
    elevation="4"
  >
    <v-container v-if="emailWasSent">
      <v-alert
        border="left"
        type="success"
        elevation="4"
        prominent
      >
        <span v-html="$t('forgot-password.email-sent-message', { emailAddress: boldEmailAddress })"></span>
      </v-alert>
    </v-container>
    <v-form v-else>
      <v-container>
        <p>{{ $t('forgot-password.description') }}</p>

        <!-- Email -->
        <v-text-field
          v-model="emailAddress"
          append-icon="mdi-email"
          class="mt-8"
          :label="$t('forgot-password.email-address-label')"
          :error-messages="validationErrors['emailAddress']"
          :error-count="1"
          autofocus
          autocomplete="email"
        />

        <!-- Send button -->
        <v-btn
          :disabled="!enableSendButton"
          :loading="isBusySendingEmail"
          block
          large
          color="primary"
          @click="sendPasswordResetEmail"
        >
          <span>{{ $t('forgot-password.send-button') }}</span>
        </v-btn>
      </v-container>
    </v-form>

    <div class="text-center">
      <v-btn
        :to="'/login'"
        plain
        class="mt-4 mb-2"
        small
      >
        <v-icon left>mdi-arrow-left-top</v-icon>
        {{ $t('forgot-password.back-to-login-button') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      emailAddress: '',
      emailWasSent: false,
      isBusySendingEmail: false,
      validationErrors: []
    }
  },
  computed: {
    enableSendButton () {
      return this.emailAddress && this.emailAddress.length > 3
    },
    boldEmailAddress () {
      return `<b>${this.emailAddress}</b>`
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    async sendPasswordResetEmail () {
      try {
        this.isBusySendingEmail = true
        await axios.post('/passwordResets/request-password-reset', {
          emailAddress: this.emailAddress
        })
        this.emailWasSent = true
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.validationErrors = error.response.data.errors
        } else if (error.response && error.response.status === 429) {
          this.showSnackbar({
            role: 'warn',
            messages: [this.$t('errors.for-code-10004')],
            duration: 10000
          })
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('forgot-password.request-reset-link-failed-unknown-reason')],
            duration: 15000
          })
        }
      } finally {
        this.isBusySendingEmail = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.limited-width {
  max-width: 400px;
}
</style>
